const Messages = {
  en: {
    MEN: "MEN",
  
  },
  fr: {
    MEN: "HOMMES",
    
  },
  es: {
    MEN: "HOMBRES",
    
  }
};

  
  export default Messages;