import React from 'react';
import Modal from 'react-modal';

import logo from '../../assets/images/logo-08-final-1.png';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 9999, // High enough to be above everything else
  },
  content: {
    width: '80%', 
    height: '70%',
    // maxHeight: '280px',
    // maxWidth: '480px',
    margin: 'auto',
    // display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '20px',
    // zIndex: 100
    backgroundColor: 'white'
  },
};

const OrderSummaryModal = ({ isOpen, closeSummaryModal, amount, orders }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeSummaryModal}
      contentLabel="Order Summary"
      style={customModalStyles}
    >
      <div className="w-full text-center pb-6">

        {/* Logo Section */}
        <div className="w-full flex justify-center border-b pb-4 bg-valorHijabTheme">
          <img
            className="block h-16 w-auto"
            src={logo}
            alt="Logo"
            style={{ cursor: 'pointer' }}
          />
        </div>

        <hr className='my-2'/>

        {/* Payment Section */}
        <div className="pt-8 pb-4 border-b">
          <p>Payment Received:</p>
          <strong style={{ fontSize: '1.5rem' }}>{'$'}{amount}</strong>
        </div>



        {/* Order Summary Section */}
        <div className="pt-4 pb-4 border-b w-full">
          <strong className="block text-left pb-2">Order Details:</strong>
          <ul className="list-none text-left">
            {orders.map((order, index) => (
              <li key={index} className="py-1">
                <span>{order.productName}</span>
                {/* <span>{order.price}</span> */}
                <span className="float-right">Qty: {order.quantity}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Thank You Note */}
        <div className="pt-4 pb-4">
          <p>Thank you for shopping with us!</p>
        </div>
      </div>

      {/* Button */}
      <div 
        className=" text-center mt-4 valor-hijab-btn bg-valorHijabTheme rounded-lg px-4 py-2 text-sm cursor-pointer"
        onClick={closeSummaryModal}
      >
        Okay
      </div>
    </Modal>
  );
};

export default OrderSummaryModal;
