import React, { useState } from 'react';
import Modal from 'react-modal';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

// import axios from 'axios';
// import LoadingScreen from '../../../LoadingScreen';


// import DatePicker from 'react-datepicker';
// import '../../../Datepicker.css';
// import { format } from 'date-fns';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import AddIcon from '@mui/icons-material/Add';
import zIndex from '@mui/material/styles/zIndex';

const customModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 9999, // High enough to be above everything else
  },
  content: {
    // width: '80%', 
    // height: '90%',
    maxHeight: '280px',
    maxWidth: '480px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center', // Center vertically
    alignItems: 'center', // Center horizontally
    borderRadius: '20px',
    // zIndex: 100
  },
};


const NotificationModal = ({ isOpen, onRequestClose, notificationType, notificationTitle, notificationMessage }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Notification"
      style={customModalStyles}
    >
      
      <div className="flex flex-col h-full w-full px-4  justify-between  relative z-50" >
        {/* <div className="flex justify-center items-center">
          <h2 className='' style={{ fontSize: '16px',  }}>{title}</h2>
        </div> */}
{/* <div>{'status: ' + notificationType.toString()}</div> */}
        <div className='flex flex-col items-center justify-center mt-4'>
        {
          notificationType === true ? 
          <CheckCircleIcon className='text-valorHijabTheme' style={{ width: '64px', height: '64px' }}/> : 
          <CancelIcon className='text-valorHijabTheme'  style={{ width: '64px', height: '64px' }}/>
        }
        <div className='flex justify-center w-full my-4 text-center'>
          {notificationMessage}                  
        </div>  
        </div>

        

        <div className='flex justify-center mb-4'>
          <div 
            onClick={() => { 
              // if (notificationType) ? () : 
              onRequestClose() 
            }}
            style={{ width: '128px', borderWidth: '1px' }}
            className='text-center  border-valorHijabTheme bg-valorHijabTheme rounded-lg px-4 py-2 text-white text-sm cursor-pointer mx-1'>
            Okay
          </div>
        </div>


        
      </div>

    </Modal>
  );
};

export default NotificationModal;
