import React, {useState, Fragment, useEffect} from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';

import Slider from "react-slick";

import Header from "./Header";
import Footer from "./Footer";

import RelatedProducts from './RelatedProducts';
// import ProductInfoTabs from './ProductInfoTabs';

import StarRateIcon from '@mui/icons-material/StarRate';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import FlashOnIcon from '@mui/icons-material/FlashOn';


import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';

import './react-css/shop.css';
// import data from './singleProductDemo.json';

// import imgx from '../../assets/images/size.png';

import CloseIcon from '@mui/icons-material/Close';

// import { FormattedMessage, IntlProvider } from 'react-intl';
// import Messages from './widget/Messages'; // Import your language messages

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


import NotificationModal from '../modals/NotificationModal';



function ProductDetails({options, addToCart, cart, updateCart, removeCartItem, removeAllCartItem, handleEmailAddress, selectedLanguage, setSiteLanguage }) {

    const navigate = useNavigate();
    const location = useLocation();
    const product = location.state.encryptedData;

    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState({});
    const [productCount, setProductCount] = useState(1);

    const [relatedProducts, setRelatedProductsData] = useState([]);
    const [isDataloading, setIsDataLoading] = useState(true);


    const [star, setStar] = useState(0);
    

    const [selectedProductId, setSelectedProductId] = useState(-1);
    const [selectedProductColor, setSelectedProductColor] = useState("");


    const handleStarClick = (rating) => {
        setStar(rating);
    };
    
    const [selectedSize, setSelectedSize] = useState('');
    const handleSizeSelection = (size) => {
      setSelectedSize(size);
    };

    const decryptedData = AES.decrypt(decodeURIComponent(product), 'encryptionKey').toString(CryptoJS.enc.Utf8);
    const parsedProduct = JSON.parse(decryptedData);
    // alert(JSON.stringify(parsedProduct, null, 2));



    // Parse the 'Color' field as an array
    let colors = [];
    try {
        colors = parsedProduct.Color ? JSON.parse(parsedProduct.Color) : [];
    } catch (error) {
        // console.error('Error parsing color field:', error);
    }


    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal


    const HandleQuickViewData = (e, item) => {
        e.preventDefault();
        setShowQuickView(!showQuickView);
        setQuickViewData(item);
    };

    const HandleQuickViewClose = (e) => {
        e.preventDefault();
        setShowQuickView(false);
        setQuickViewData({});
    };

    const settings = {
        customPaging: function (i) {
          const productImage = parsedProduct.Image_Url;
          return (
              <a key={i}>
              {productImage && (
                <img
                  src={productImage.Image_Url}
                  alt={`Thumbnail ${i}`}
                  style={{ width: '100px', height: 'auto' }}
                />
              )}
              </a>
          );
       },
       infinite: true,
       speed: 600,
       autoplaySpeed: 4000,
       slidesToShow: 1,
       slidesToScroll: 1,
       autoplay: true,
       dots: true,
       dotsClass: "slick-dots slick-thumb slider-nav",
       arrows: false,
       //vertical: true,
       //verticalSwiping: true,
   };



    // Function to find the lowest price among product variants
function findLowestPrice(product) {
    // let lowestPrice = Infinity;
  
    // //products.forEach(product => {
    //   product.productVariants.forEach(variant => {
    //     if (variant.price < lowestPrice) {
    //       lowestPrice = variant.price;
    //     }
    //   });
    // //});
  
    // // return lowestPrice;
    // return lowestPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return product.Price;
  }

  function findHighestPrice(product) {
    let highestPrice = 0;
  
    //products.forEach(product => {
      product.productVariants.forEach(variant => {
        if (variant.price > highestPrice) {
            highestPrice = variant.price;
        }
      });
    //});
  
    return highestPrice;
  }

  // Function to calculate the discount percentage
function calculateDiscountPercentage(price, oldPrice) {
    return parseInt(price) < parseInt(oldPrice) ?
      Math.round(((parseInt(oldPrice) - parseInt(price)) / parseInt(oldPrice)) * 100)
      : 0; // Return 0 if there's no discount
  }

    useEffect(() => {
        window.scrollTo(0, 0);
          handleData();
    }, []);

    const handleData = async () => {    
        setIsDataLoading(true);
        try {
    
          const response = await axios.get(process.env.REACT_APP_API_SERVER_URL + '/response/vh-read-items.php', {
            //params: { uid: uid },
            headers: {
              "Content-Type": "application/json",

            },
          });
    
          setIsDataLoading(false);
    
          if (response.data.status) {
    
            setRelatedProductsData(response.data.itemsData);
          } else {
           // alert("error: " + response.data.message);
          }
    
        } catch (error) {
          setIsDataLoading(false);
          //alert("error: " + error);
        }
      };

      const handleIncreaseQuantity = (item) => {
        setProductCount(productCount + 1);
    };
    
    const handleDecreaseQuantity = (item) => {
        if (productCount > 1) {
            setProductCount(productCount - 1);
        }
        
    };

    const handleBuyNow = () => {
        // addToCart(parsedProduct, productCount);

        showAddedDialogue(0);addToCart(parsedProduct, productCount);

        setAddedItemName(parsedProduct.name);

        navigateToCheckOut();
    
    };




    const [showItemAdded, setShowItemAdded] = useState(false);
const [showIndexItemAdded, setShowIndexItemAdded] = useState(-1);
const showAddedDialogue = (i) => {
  setShowIndexItemAdded(i);
  // alert("");
  setShowItemAdded(true);
  setTimeout(() => {
    setShowItemAdded(false);
  }, 1000);
}
const [addedItemName, setAddedItemName] = useState('');


const [showSizeChart, setShowSizeChart] = useState(false);
const showSizeChartDialogue = (i) => {
    // setShowIndexItemAdded(i);
    // alert("");
    setShowSizeChart(true);
    setTimeout(() => {
        // setShowSizeChart(false);
    }, 3000);
  }


  const navigateToCheckOut = () => {
    if (cart.length > 0) {
        options.onMiniCartClick();
        const encryptedData = AES.encrypt(JSON.stringify(cart), 'encryptionKey').toString();
        navigate('/checkout', { state: { encryptedData } });
    } else {
        // alert("Add items to your cart");
        openNotificationModal(false, "Valor Hijab - Cart", "Add items to your cart");
    }
};

// function mainProductImage(product) { 
//   return product.productImages.find(img => img.isDefault).url;
// }


const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
const [animateCarousel, setAnimateCarousel] = useState(false);
const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true });
const carouselItemVariants = {
    hidden: { opacity: 0, y: 200 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 0.5, type: 'spring', stiffness: 20 } },
  };    
  useEffect(() => {    
    if (inView) {
      setAnimateCarousel(true);
    }

    

  }, [inView, hoveredMenuItem]);



    return (
      
        <div className='relative bg-valorHijabTheme'>
            
        <Header options={options} cart={cart} setSiteLanguage={setSiteLanguage} addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}/>


{/* The background image overlay */}
<img 
    src={process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />


        <motion.div
            variants={carouselItemVariants}
            initial="hidden"
            animate={ animateCarousel ? "visible" : "hidden"}
            ref={ref}
          >
            


            <section className="shop-single-section -mt-16 shop-single-vertical-thumb section-padding  relative z-10">
                <div className="container-1410 ">
                    <div className="row  md:px-4 lg:px-16 xl:px-8 2xl:px-36 ">
                    


                    {/* <div className='scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100' style={{ height: 'calc(100vh - 80px)', overflowY: 'auto' }}> */}
      

                        <div className="col col-md-6">
                            <div className="shop-single-slider vertical-thumbnail" 
                            //  style={{ background: '#FF0000' }}
                            >
                                {/* <Slider {...settings}>
                                    {
                                        generateCustomPaging()
                                    }
                                </Slider> */}

<div className="flex flex-col md:flex-row rounded-lg bg-white overflow-hidden">
                                    <div className="flex flex-col md:flex-row rounded-lg">
                                        <div className="flex flex-grow rounded-lg">
                                            <img
                                                className=" rounded-lg p-4"
                                                // src={productImage}
                                                src={process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
                                                alt="Logo"
                                                // onClick={() => navigateToProduct(item)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>




                                    <div className="col col-md-6">
                            <div className="product-details">
                                <h2 className='pl-4' style={{ fontSize: '24px' }}>{parsedProduct.Item_Name}</h2>


                                <div>
                    {/* Render the color swatches */}
                    {colors.length > 0 ? (
                        <div className="flex flex-wrap">
                        {colors.map((color, index) => (
                          <div
                            key={index}
                            className="m-1 cursor-pointer p-1"
                            style={{
                              borderRadius: '25%',
                              border: (selectedProductId === parsedProduct.Id && color === selectedProductColor)
                                ? '2px solid #4B181F'  // Black border when selected
                                : '2px solid #fff', // White border when not selected
                              boxShadow: (selectedProductId === parsedProduct.Id && color === selectedProductColor)
                                ? '0px 0px 8px rgba(0, 0, 0, 0.3)'  // Shadow effect for selected color
                                : 'none',
                              padding: '4px',  // Space around the color circle
                              display: 'inline-flex',  // Ensure proper layout inside flex container
                            }}
                            onClick={() => {
                              setSelectedProductId(parsedProduct.Id);
                              setSelectedProductColor(color);
                            }}
                          >
                            <div
                              style={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: color,  // Display the actual color
                                borderRadius: '25%',     // Perfect circle for the color
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      
                    ) : (
                        <p>No colors available</p> // Optional fallback if no colors are available
                    )}
                </div>
                                

                                <div className="price" style={{ fontSize: '20px' }}>
                                    <span className="pl-4">{'$'}{findLowestPrice(parsedProduct)}</span>
                                </div>
                                {/* <div className="rating">
                                    <a className="star-1" onClick={() => handleStarClick(1)} style={{ cursor: 'pointer' }}>
                                <StarRateIcon style={{width: '20px', height: '20px', color: star < 1 ? 'grey' : '#c25f2b' }}/>
                            </a>
                            <a className="star-1" onClick={() => handleStarClick(2)} style={{ cursor: 'pointer' }}>
                                <StarRateIcon style={{width: '20px', height: '20px',  color: star < 2 ? 'grey' : '#c25f2b' }}/>
                            </a>
                            <a className="star-1" onClick={() => handleStarClick(3)} style={{ cursor: 'pointer' }}>
                                <StarRateIcon style={{width: '20px', height: '20px',  color: star < 3 ? 'grey' : '#c25f2b' }}/>
                            </a>
                            <a className="star-1" onClick={() => handleStarClick(4)} style={{ cursor: 'pointer' }}>
                                <StarRateIcon style={{width: '20px', height: '20px',  color: star < 4 ? 'grey' : '#c25f2b' }}/>
                            </a>
                            <a className="star-1" onClick={() => handleStarClick(5)} style={{ cursor: 'pointer' }}>
                                <StarRateIcon style={{width: '20px', height: '20px',  color: star < 5 ? 'grey' : '#c25f2b' }}/>
                            </a>
                            </div> */}



                                {/* <span className="tagged_as" style={{ display: 'flex', alignItems: 'center', color: '#777777', height: '30px',  }}>
                                    <div>SIZE: </div>
        <div onClick={() => handleSizeSelection('S')} className={`text-center ${selectedSize === 'S' ? 'bg-black text-white' : 'bg-white'} ml-2 mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '40px', cursor: 'pointer' }}>S</div>
        <div onClick={() => handleSizeSelection('M')} className={`text-center ${selectedSize === 'M' ? 'bg-black text-white' : 'bg-white'} mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '40px', cursor: 'pointer' }}>M</div>
        <div onClick={() => handleSizeSelection('L')} className={`text-center ${selectedSize === 'L' ? 'bg-black text-white' : 'bg-white'} mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '40px', cursor: 'pointer' }}>L</div>
        <div onClick={() => handleSizeSelection('XL')} className={`text-center ${selectedSize === 'XL' ? 'bg-black text-white' : 'bg-white'} mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '40px', cursor: 'pointer' }}>XL</div>
        <div onClick={() => handleSizeSelection('XXL')} className={`text-center ${selectedSize === 'XXL' ? 'bg-black text-white' : 'bg-white'} mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '40px', cursor: 'pointer' }}>XXL</div>
        <div onClick={() => showSizeChartDialogue()} className={`text-center ${selectedSize === 'XXL' ? 'bg-black text-white' : 'bg-white'} mx-1`} style={{ border: '1px solid #ccc', padding: '5px', width: '90px', cursor: 'pointer' }}>Size Chart</div>
        
                                </span> */}



                                <div className="product-option flex flex-col md:flex-row">
                                  
    {/* <div className="w-full md:w-1/4 valor-hijab-btn bg-valorHijabTheme rounded-lg  px-4 py-2 text-sm text-center md:ml-2" style={{ cursor: 'pointer' }} onClick={() => {}}>Subscribe</div> */}

                                  
                                <div className="product-row flex items-center bg-valorHijabTheme mx-2 my-1 rounded-lg text-sm">

                                <div className="touchspin-wrap" style={{ display: 'flex', alignItems: 'center' }}>
            
            <div className='flex bg-white items-center justify-center m-2 rounded-lg' style={{ height: '80%', width: '100%' }}>

                <RemoveIcon className='' style={{ cursor: 'pointer', width: '40px' }} 
                onClick={() => { handleDecreaseQuantity(parsedProduct) }}
                />
                
                <span className='flex justify-center items-center text-center' style={{ width: '60px' }}>{productCount}</span>

                <AddIcon className='' style={{ cursor: 'pointer', width: '40px' }} 
                onClick={() => { handleIncreaseQuantity(parsedProduct) }}
                />
                
            </div>

        </div>


                                    
                                    <div className=''>
                                        <div className='p-4 cursor-pointer text-white ml-8 text-sm bg-valorHijabTheme' 
                                        // onClick={
                                        //     () => {
                                        //         showAddedDialogue(0);addToCart(parsedProduct, productCount);

                                        //         setAddedItemName(parsedProduct.Item_Name);
                                        //     }                                        
                                        // }
                                        onClick={() => {
                                          if (colors.length > 0 && selectedProductColor != "" && selectedProductId === parsedProduct.Id) {
                                            // addToCart('/product-details');
                                            showAddedDialogue(0);
                                            setAddedItemName(parsedProduct.Item_Name);                                            
                                            addToCart(parsedProduct, 1, selectedProductColor);
                                          } else {
                                            openNotificationModal(false, "Valor Hijab", "Add Hijab: Please select a color.");
                                          }
                                            
                                        }}                                        
                                        >ADD TO CART</div>
                                    </div>
                                    

                                    
                                </div>

                                {/* <div  className='flex bg-black  justify-center mx-2 my-1'>
                                        <button className='p-4 font-bold text-white  text-sm' 
                                        onClick={handleBuyNow}

                                        
                                        ><FlashOnIcon /> BUY NOW</button>
                                    </div> */}


                            {/* </form> */}
                        </div>
                                <p>{parsedProduct.description}</p>
                                
                                 
                                <div className="thb-product-meta-before">
                                
                                    <div className="product_meta">
                                    
                                  
                                {/* <span className="tagged_as" style={{ display: 'flex', alignItems: 'center', color: '#777777', height: '30px',  }}>
                                    <div>SHARE: </div>
                                    <FacebookIcon className='ml-2 mr-4' style={{ height: '16px', width: '16px', cursor: 'pointer' }} /> 
                                    <InstagramIcon className='mr-4' style={{ height: '16px', width: '16px', cursor: 'pointer' }} /> 
                                    <ContentCopyIcon className='mr-4' style={{ height: '16px', width: '16px', cursor: 'pointer' }} />
                                    
                                </span> */}
                                    </div>
                                </div>

                                
                            </div>
                        </div>



                                </div>
                                
                                
                                
                                
                                




                                <div className="slider-nav"></div>

                                
                            </div>
                        </div>
                        
                        {/* end col */}
                    </div>
                    {/* end row */}
                    <div className="row">
                        {/* <div className="col col-md-8 col-md-offset-2">
                            <ProductInfoTabs/>
                        </div> */}
                    </div>
                    {/* end row */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <RelatedProducts onQuickViewClick={HandleQuickViewData} relatedProducts={relatedProducts} addToCart={addToCart} cart={cart} selectedLanguage={selectedLanguage}/>
                        </div>
                    </div>
                </div>

                {showItemAdded ?

(
  <div 
    
  className="flex absolute bg-valorHijabTheme p-4 rounded-lg mt-2 text-lg z-50 items-center justify-center text-white" 
    style={{ 
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      width: '240px',
      height: '100px',
    }}
  >
    {addedItemName} added to your cart !!
  </div>
) : ''}

                {/* end of container */}
            </section>
            </motion.div>


            <Footer />



            <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
        </div>
    );
}

export default ProductDetails;