import React, {Fragment, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';


import NotificationModal from '../modals/NotificationModal';


function CalculatedShipping({currencySymbol, price, tax, options, cart}) {

    const navigate = useNavigate();


    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal




    const onClickSubmit = (e) => {
        e.preventDefault();
    };
    
    const calculateGrandTotal = () => {
        let grandTotal = price + tax;
    return grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

    };

    const navigateToCheckOut = () => {
        //alert(cart.length);
        if (cart.length > 0) {
            //options.onMiniCartClick();
            const encryptedData = AES.encrypt(JSON.stringify(cart), 'encryptionKey').toString();
            // navigate(`/checkout/${encodeURIComponent(encryptedData)}`);
            navigate('/checkout', { state: { encryptedData } });
        } else {
            // alert("Your cart is empty. Please add items to your cart.");
            openNotificationModal(false, "Valor Hijab - Checkout", "Your cart is empty. Please add items to your cart.");
        }
        
      };

    return (
        <Fragment>
            <div className="cart_totals calculated_shipping mb-4">
                <h3>Cart Totals</h3>
                <table className="shop_table shop_table_responsive ">
                    <tbody>
                    <tr className="cart-subtotal" 
                    // style={{ textAlign: 'right' }}
                    >
                        <th>Subtotal:</th>
                        <td data-title="Subtotal">
                            <span className="woocommerce-Price-amount amount">
                                    <span className="woocommerce-Price-currencySymbol">
                                        {currencySymbol}
                                    </span>{price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>
                        </td>
                    </tr>
                    <tr className="cart-subtotal" 
                    // style={{ textAlign: 'right' }}
                    >
                        <th>Tax:</th>
                        <td data-title="Subtotal">
                            <span className="woocommerce-Price-amount amount">
                                    <span className="woocommerce-Price-currencySymbol">
                                        {currencySymbol}
                                    </span>{tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>
                        </td>
                    </tr>
                    
                    <tr className="order-total" 
                    // style={{ textAlign: 'right' }}
                    >
                        <th>Total:</th>
                        <td data-title="Total">
                            <strong>
                                <span className="woocommerce-Price-amount amount    bg-valorHijabTheme px-2 py-1 text-white rounded-lg">
                                    <span className="woocommerce-Price-currencySymbol">{currencySymbol}</span>{calculateGrandTotal()}
                                </span>
                            </strong>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="valor-hijab-btn bg-valorHijabTheme rounded-lg mt-4 px-4 py-2 text-sm text-center relative z-20 mx-4" style={{ cursor: 'pointer' }} 
                onClick={navigateToCheckOut}>Proceed to Checkout</div>
                

                <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
            </div>
        </Fragment>
    );
}

export default CalculatedShipping;