
export default function ItemAddedModal({ addedItemName }) {
return(

<div
            className="flex absolute bg-valorHijabTheme p-4 rounded-lg mt-2 text-lg z-50 items-center justify-center text-white"
            style={{ 
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                width: '240px',
                height: '100px',
            }}
            >
                {addedItemName} added to your cart !!
                </div>
                );
            }