import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import logo from '../../assets/images/logo-08-final-1.png';


import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {

  const navigate = useNavigate();

  useEffect(() => {}, []);



  return (
    <div className=''>
         <div className='w-full text-white' style={{  }}>
     <div className=' 
      md:px-4 lg:px-16 xl:px-24 2xl:px-64  md:py-4  pb-4 
     flex flex-col'>


      <div className='flex flex-col items-center justify-between py-8 px-16 md:flex-row'>
       <div className="flex items-center mb-4">
          {/* <img
            className="block h-14 w-auto max-w-none"
            src={logo}
            alt="Logo"
            onClick={() => {navigate('/');}}
            style={{ cursor: 'pointer' }}
          /> */}
        </div>
        
        {/* <div className="flex flex-col">
            <p className=' text-white '>Download our Mobile App</p>
            <p className=' text-white text-sm'>Get access to exclusive deals today</p>
            <div className='flex mt-2'>
              <div className='mr-2  border border-white rounded-lg px-4 py-2 text-white text-sm'>Get it on</div>
              <div className='ml-2  border border-white rounded-lg px-4 py-2 text-white text-sm'>Get it on</div>
           </div>
         </div> */}
      </div>
    



     <div className='flex flex-col  h-auto px-8 md:px-16  md:h-52 '>
      <div className="flex flex-col items-start md:flex-row md:items-center md:justify-between mb-4">
        <div className="flex flex-col md:w-1/4 md:items-start my-4 text-sm  md:pr-12  pb-2">
          <p className='text-lg mb-1'>Social Media</p>
            {/* <p>We represent the strength and resilience of every woman.</p> */}
            {/* <p>One act of bravery has the power to transform everything.</p> */}

            <div className="relative z-20">
              <ul className="flex">
                <li className='mr-4'>
                  <a href="https://www.facebook.com/" target='_blank'>
                    <FacebookIcon style={{cursor: "pointer", color: "#ffffff" }}/>
                  </a>
                </li>
                <li className='mr-4'>
                  <a href="https://www.twitter.com/" target='_blank'>
                    <TwitterIcon style={{cursor: "pointer", color: "#ffffff" }}/>
                  </a>
                </li>
                <li className='mr-4'>
                  <a href="https://www.instagram.com/valor.hijab/profilecard/?igsh=MXM1M202OHB1cWZrZw%3D%3D" target='_blank'>
                    <InstagramIcon style={{cursor: "pointer", color: "#ffffff" }}/>
                  </a>
                </li>
              </ul>
            </div>
        </div>

        






        {/* <div className="flex flex-col md:w-1/4 md:items-start my-4 text-sm text-white md:px-16 pb-2">
            <p className='text-lg mb-1'>Quick Shop</p>
            <p>New Arrivals</p>
            <p>Sets & Bundles</p>
            <p>Cups and Mugs</p>
            <p>Gifts</p>
        </div> */}

        {/* <div className="flex flex-col md:w-1/4 md:items-start my-4 text-sm text-white md:px-12 pb-2">
            <p className='text-lg mb-1'>Company</p>
            <p>Shipping</p>
            <p>Returns & Exchanges</p>
        </div> */}
        
         <div className="flex flex-col md:w-1/4 md:items-start my-4 text-sm md:px-12 pb-2">
            {/* <p className='text-lg mb-1'>Contact Us</p> */}
            {/* <p>Socials: </p> */}
            {/* <p>Text or Email</p>
            <p>24 / 7</p> */}
            {/* <p>Email: hello@valorhijab.com</p> */}
        </div>
      </div>

      

    </div>



    
    <div className="flex flex-col md:flex-row items-center justify-between md:items-start px-16 md:px-16 text-xs  w-full py-2">
            <p className='text-center mt-2 mb-1'>Copyright &copy; 2024 Valor Hijab. All rights reserved</p>
            <div className='flex justify-center mt-1 mb-2'>
              <p className='mx-2'>Terms of Service</p>
              <p className='mx-2'>Privacy Policy</p>
              {/* <p className='mx-2'></p> */}
            </div>
            
        </div>
    
    </div>    
    </div>
    </div>
  );
}

export default Footer;
