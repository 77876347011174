import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

import Header from "./Header";
import Footer from "./Footer";
import ShippingFields from './ShippingFields';


import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';

import axios from 'axios';


import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


import {loadStripe} from '@stripe/stripe-js';


import PaymentModal from './PaymentModal';
import OrderSummaryModal from './OrderSummaryModal';


import NotificationModal from '../modals/NotificationModal';
//
import axiosInstance from '../../axiosConfig'; // Ensure the correct relative path
import { setCookie } from '../../authUtils'; // Ensure the correct relative path
import { jwtDecode } from 'jwt-decode';
import { getCookie, deleteCookie } from '../../authUtils'; // Import getCookie function
//


function CheckoutPage({ options, setSiteLanguage, addToCart, updateCart, removeCartItem, removeAllCartItem, clearCart, selectedLanguage }) {

    const navigate = useNavigate();
    
    const location = useLocation();
    const cart = location.state.encryptedData;
    const decryptedData = AES.decrypt(decodeURIComponent(cart), 'encryptionKey').toString(CryptoJS.enc.Utf8);
    const [parsedCart, setParsedCart] = useState(JSON.parse(decryptedData)); // State to store parsed cart
    const [cartItems, setCartItems] = useState(parsedCart); // Define cartItems state variable
    const [isDataloading, setIsDataLoading] = useState(true);
    //alert(JSON.stringify(parsedCart, null, 2));
    const [requestData, setRequestData] = useState([]);
    
    const [amount, setAmount] = useState(0);
    
    
    const [isHoverCheckout, setIsHoverCheckout] = useState(false);
    const handleHoverCheckout = () => { setIsHoverCheckout(true); };
    const handleLeaveCheckout = () => { setIsHoverCheckout(false); };


    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");

    
    const [postalCode, setPostalCode] = useState("");
    
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [country, setCountry] = useState("");
    const [orderNotes, setOrderNotes] = useState("");
    
    const [paymentMethod, setPaymentMethod] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let errorOrderItems = [];


    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const storedUserDetails = getCookie('valorhijab-userDetails');
    const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
    useEffect(() => {
        if (storedUserDetails != null) {

            setUserIsLoggedIn(true);

            setFirstname(userDetails.Firstname)
            setLastname(userDetails.Lastname)
            setEmail(userDetails.Email)
            setPhoneNumber(userDetails.Phone_Number)
            setAddress1(userDetails.Delivery_Address.address1);
            setAddress2(userDetails.Delivery_Address.addres2);
            setPostalCode(userDetails.Delivery_Address.postalCode);
            setCity(userDetails.Delivery_Address.city);
            setState(userDetails.Delivery_Address.state);
            setCountry(userDetails.Delivery_Address.country);
        }
    }, [userDetails]);


    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal



    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const [showOrderSummaryModal, setShowOrderSummaryModal] = useState(false);
      const closeSummaryModal = () => {
        clearCart();
        setShowOrderSummaryModal(false);
        navigate("/");
      };

      const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState("");
    const closePaymentModal = () => {
        setShowPaymentModal(false);
      };

      
      function findLowestPrice(product) {
        // let lowestPrice = Infinity;
      
        // //products.forEach(product => {
        //   product.productVariants.forEach(variant => {
        //     if (variant.price < lowestPrice) {
        //       lowestPrice = variant.price;
        //     }
        //   });
        // //});
      
        // return lowestPrice;
        return product.Price;
      }

      const calculateCartSubTotal = () => {
        let subTotal = 0;
        parsedCart.forEach((item) => {
            subTotal += findLowestPrice(item) * item.quantity;
        });
    
        // const taxRate = 0.075; // 7.5%
        // const calculatedTax = subTotal * taxRate;
        // setTax(calculatedTax);
    
        return subTotal;
    };

    const calculateCartTax = () => {
        let subTotal = 0;
        parsedCart.forEach((item) => {
            subTotal += findLowestPrice(item) * item.quantity;
        });
    
        const taxRate = 0.075; // 7.5%
        const calculatedTax = subTotal * taxRate;
        
        return calculatedTax;
    };
  
    function createOrderItems(products) {
    // Initialize an array to store order items
    let orderItems = [];

    // Iterate through each product
    products.forEach(product => {
        // Push the order item to the orderItems array
        orderItems.push({
            productId: product.Id,
            productName: product.Item_Name,
            quantity: product.quantity,
            selectedColor: product.selectedColor
        });
    });

    // alert(JSON.stringify(orderItems), null, 2);

    return orderItems;
}

const calculateGrandTotal = () => {
    const subTotal = calculateCartSubTotal();
    const tax = calculateCartTax();
    const total = subTotal + tax;
    return total.toFixed(2); // Return the total amount without updating state
}


    const handlePaymentTypeChange = (p) => {
        setPaymentMethod(p);
        // alert(p);
    }



    useEffect(() => {
        window.scrollTo(0, 0);

        const totalAmount = calculateGrandTotal() * 100;

        setAmount(totalAmount); 
        
        // Update parsedCart when cartItems change
        setParsedCart(cartItems);
    }, [cartItems]);


    const createOrder = async () => {
        try {
    

            
            //alert(JSON.stringify(requestData), null, 2);

    
            const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + '/response/vh-create-order.php', requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            
            setIsLoading(false);
    
            
            if (response.data.status) {
    
                clearCart();
                setShowPaymentModal(false);
                setShowOrderSummaryModal(true);
    
               //alert("Success");
    
            } else {
                // alert("Order creation error");
                openNotificationModal(false, "Order", "Order creation error");
            }
        } catch (error) {
            setIsLoading(false);    
    
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                const cartItems = errorOrderItems;//createOrderItems(parsedCart); // Get the cart items here
                
                let errorMessage = "Errors occurred while processing your request:\n\n";
            
                errors.forEach(err => {
                    const productName = getProductName(cartItems, err.value); // Get the product name from the cart items
                    errorMessage += `Error: ${err.msg} for product: ${productName}\n\n`;
                });
            
                // alert(errorMessage);
                openNotificationModal(false, "Order", errorMessage);
            } else if (error.response && error.response.data && error.response.data.message) {
                // If there's an error message in the response data, display it
                // alert(error.response.data.message);
                openNotificationModal(false, "Order", error.response.data.message);
            } else {
                // alert("An error occurred while processing your request.");
                openNotificationModal(false, "Order", "An error occurred while processing your request.");
            }
            
        }
    }



    const checkPaymentValues = async () => {

        //alert(paymentMethod);
        // alert(email);
    
         // Validate email before proceeding
         if (!isValidEmail(email)) {
            // alert('Please enter a valid email address');
            openNotificationModal(false, "Payment", "Please enter a valid email address");
            //setRegistrationStatus("Invalid email address");
            //setIsModalOpen(true);
            return;
          }
    
    
        // Create an array to hold the names of required fields
        const requiredFields = [
            'firstname', 
            'lastname', 
            'email', 
            'phoneNumber', 
            'address1', 
            // 'postalCode', 
            'city', 
            // 'state', 
            // 'country', 
            'paymentMethod'
        ]; //
       
    
        // Check if any of the required fields are empty
        const emptyFields = requiredFields.filter(field => !eval(field));
    
        if (emptyFields.length > 0) {
            // alert(emptyFields);
            if (emptyFields != "paymentMethod") {
                
            // Show an alert indicating the empty required fields
            // alert(`Please fill in the remaining ${emptyFields.length} required fields: ${emptyFields.join(', ')}`);
            openNotificationModal(false, "Payment ", `Please fill in the remaining ${emptyFields.length} required fields: ${emptyFields.join(', ')}`);
            // alert(`Please fill in the following required fields: ${emptyFields.join(', ')}`);
            } else {
                // alert(`Please select a payment method.`);
                openNotificationModal(false, "Payment ", "Please select a payment method.");
            }
            return;
        }
    
        setIsLoading(true);

        if (paymentMethod !== "stripe") {
            // alert("Please select a payment method");
            openNotificationModal(false, "Payment Method", "Please select a payment method");
            return;
        }

        const customerShippingDetailsData = {
            last_modified_by: "username",
            username: "username",
            firstname: firstname,
            lastname: lastname,
            email: email,
            delivery_address: {    
                    phone: phoneNumber,
                    address1: address1,
                    address2: address2,
                    postalCode: postalCode,
                    city: city,
                    state: state,
                    country: country
                },
            
            orders: createOrderItems(parsedCart),
            price: calculateGrandTotal(),
            tax_id: 1,
            payment_method: paymentMethod
        };
    
        setRequestData(customerShippingDetailsData);
        setIsLoading(false);

        // alert("oh");
        setShowPaymentModal(true);
    
        
    };
    
    function getProductName(cartItems, productVariantId) {
        const cartItem = cartItems.find(item => item.productVariantId === productVariantId);
        return cartItem ? cartItem.productName : "Unknown Product";
    }
    
    
    
    const navigateToCart = () => {
        
            // options.onMiniCartClick();
            const encryptedData = AES.encrypt(JSON.stringify(cartItems), 'encryptionKey').toString();
            navigate('/cart', { state: { encryptedData } });
        
    };



    return(
        <div className='relative  bg-valorHijabTheme ' style={{  }}>
            <Header options={options} cart={parsedCart} setSiteLanguage={setSiteLanguage} addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}/>



            <img 
    src={'https://www.valorhijab.com/api/v1/vh-images/Pleated.jpeg'} //{process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />



            <div className="mx-8 my-8 rounded-2xl  bg-white overflow-hidden       relative z-20">
                <div className="flex flex-col md:flex-row" style={{  }}>

                <div className="w-70 md:w-2/3 lg:w-3/4 xl:w-70  bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Billing Details</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 px-4 py-5 sm:grid-cols-3 sm:px-6">
                            <div>
                                <label htmlFor="billing_first_name" className="block text-sm font-medium text-gray-700">
                                First name
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Enter your first name"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                                readOnly={userIsLoggedIn && firstname != "" ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_last_name" className="block text-sm font-medium text-gray-700">
                                Last name
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Enter your last name"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                                readOnly={userIsLoggedIn && lastname != "" ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <input
                                type="email"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Enter email address"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                readOnly={userIsLoggedIn && email != "" ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_phone" className="block text-sm font-medium text-gray-700">
                                    Phone
                                </label>
                                <input
                                type="tel"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Enter phone number"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                                readOnly={userIsLoggedIn && phoneNumber != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_address_1" className="block text-sm font-medium text-gray-700">
                                    Street address
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Street address"
                                onChange={(e) => setAddress1(e.target.value)}
                                value={address1}
                                readOnly={userIsLoggedIn && address1 != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_address_2" className="block text-sm font-medium text-gray-700">
                                (optional) Address
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Apartment, Suite, Unit etc."
                                onChange={(e) => setAddress2(e.target.value)}
                                value={address2}
                                readOnly={userIsLoggedIn && address2 != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="City"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                                readOnly={userIsLoggedIn && city != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_postcode" className="block text-sm font-medium text-gray-700">
                                    Postcode / ZIP
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="Postalcode"
                                onChange={(e) => setPostalCode(e.target.value)}
                                value={postalCode}
                                readOnly={userIsLoggedIn && postalCode != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_state" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                placeholder="State"
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                                readOnly={userIsLoggedIn && state != ""  ? true : false }
                                />
                            </div>
                            <div>
                                <label htmlFor="billing_country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <input
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                                    placeholder="Country"
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                    readOnly={userIsLoggedIn && country != ""  ? true : false }
                                />
                            </div>
                        </div>

                        <ShippingFields setSiteLanguage={setSiteLanguage} selectedLanguage={selectedLanguage}/>
                    </div>
                </div>
                <div className="w-30 md:w-1/3 lg:w-1/4 xl:w-30 py-4 mt-4 px-4">
                    <h3 id="order_review_heading">Your Order</h3>
                        <div id="order_review" className="woocommerce-checkout-review-order">
                                        
                                        
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                    {/* <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th> */}
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item{parsedCart.length > 1 ? 's (' + parsedCart.length + ')' :'(' + parsedCart.length + ')'}</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {parsedCart.map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                        {/* <td className="px-3 py-4 text-left whitespace-nowrap">
                                            <span className="text-sm text-gray-900">{index}</span>
                                        </td> */}
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{item.Item_Name}</div>
                                            <div className="text-sm text-gray-500">{item.quantity}</div>
                                        </td>
                                        <td className="px-6 py-4 text-right whitespace-nowrap">
                                            <span className="text-sm text-gray-900">{'$'}{findLowestPrice(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                <tfoot className="bg-gray-50">
                                    <tr className="bg-white divide-y divide-gray-200">
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtotal</th>
                                            <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                <span className="text-sm text-gray-900">{'$'}{calculateCartSubTotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                            </td>
                                    </tr>
                                    {/* You can add more rows here for additional details */}
                                    <tr className="bg-white divide-y divide-gray-200">
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tax (7.5%)</th>
                                        <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <span className="text-sm text-gray-900">{'$'}{calculateCartTax().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50">
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                                        <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            <span className="text-sm text-gray-900">{'$'}{calculateGrandTotal().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                            
                            
                            
                            <div id="payment" className="flex flex-col items-start justify-center px-4 py-4">
                                <div 
                                // className='flex border-2 border-gray-200 p-4 w-full rounded-lg'
                                >
                                    <h3 id="">Payment Method </h3>
                                    <hr className='my-2'/>
                                    {/* <label className='flex items-center cursor-pointer' style={{ height: '32px', textAlign: 'center' }}>
                                        <input
                                        type="radio"
                                        name="flutterwaveOption"
                                        value="flutterwave"
                                        className='mr-2 flex items-center' 
                                        checked={paymentMethod === 'flutterwave'} // Check if this option is selected
                                        onChange={() => handlePaymentTypeChange("flutterwave")}
                                        />&nbsp;&nbsp;Flutterwave
                                    </label>


                                    <label className='flex items-center cursor-pointer' style={{ height: '32px', textAlign: 'center' }}>
                                        <input
                                        type="radio"
                                        name="paystackOption"
                                        value="paystack"
                                        className='mr-2 flex items-center' 
                                        checked={paymentMethod === 'paystack'} // Check if this option is selected
                                        onChange={() => handlePaymentTypeChange("paystack")}
                                        />&nbsp;&nbsp;Paystack
                                    </label> */}

                                    <label className='flex items-center cursor-pointer' style={{ height: '32px', textAlign: 'center' }}>
                                    <input
                                        type="radio"
                                        name="squadOption"
                                        value="stripe"
                                        className='mr-2 flex items-center' 
                                        checked={paymentMethod === 'stripe'} // Check if this option is selected
                                        onChange={() => handlePaymentTypeChange("stripe")}
                                        />&nbsp;&nbsp;Stripe
                                    </label>
                                </div>
                                {/* <div className='flex justify-between w-full mt-6' >
                                    <div className="view-cart-btn mr-2 flex items-center" style={{ cursor: 'pointer' }}  onClick={navigateToCart} >
                                        Modify Cart {'(' + parsedCart.length + ')'} 
                                    </div>
                                    <div className="checkout-btn ml-2 flex items-center" style={{ cursor: 'pointer' }}  onClick={payNow}>
                                        Pay Now 
                                        <ArrowRightAltIcon 
                                            // onClick={payNow} 
                                            style={{ cursor: 'pointer', width: isHoverCheckout ? '32px' : '44px', transition: 'width 0.3s ease' }} 
                                            className="ml-2"
                                            // onMouseEnter={handleHoverCheckout}
                                            // onMouseLeave={handleLeaveCheckout}
                                        />
                                    </div> 
                                </div> */}

                                <div className='flex-col w-full justify-between'>
                                    <div className="valor-hijab-btn bg-valorHijabTheme rounded-lg mt-4 px-4 py-2 text-sm text-center" style={{ cursor: 'pointer' }} onClick={navigateToCart}>Modify Cart {'(' + parsedCart.length + ')'}</div>
                                    <div className="valor-hijab-btn bg-valorHijabTheme rounded-lg mt-4 px-4 py-2 text-sm text-center" style={{ cursor: 'pointer' }} 
                                    onClick={() => checkPaymentValues() }
                                    >Checkout</div>
                                </div>

                                
                                
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>

            <PaymentModal
              isOpen={showPaymentModal}
              closePaymentModal={closePaymentModal}
            //   registrationStatus={registrationStatus}
            //   registrationError={errorMessage}
            amount={amount}
            createOrder={createOrder}
            />
            
            <OrderSummaryModal
              isOpen={showOrderSummaryModal}
              closeSummaryModal={closeSummaryModal}
            //   registrationStatus={registrationStatus}
            //   registrationError={errorMessage}
            amount={calculateGrandTotal()}
            orders={createOrderItems(parsedCart)}
            />


            <Footer/>



            <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
        </div>
    );
}

export default CheckoutPage;
