import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ClipLoader } from 'react-spinners';

// import logo2 from '../../../assets/logos/Logo Wordmark.png';



function Loading({ type }) {

    const navigate = useNavigate();

    return (
      <div className="flex flex-col items-center justify-center min-h-screen  z-50"
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
      >
      <div className="flex items-center justify-center mb-4">
        {/* <img
          className="block h-8 w-auto"
          src={logo2}
          alt="Logo"
          onClick={() => { navigate('/'); }}
          style={{ cursor: 'pointer' }}
        /> */}
      </div>
      <div className="flex justify-center mb-4">
        <ClipLoader size={50} color={"#000000"} loading={true} /> 
      </div>
      <div className="flex justify-center text-lg">Loading items...</div>
    </div>

         
    );
}

export default Loading;
